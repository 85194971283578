import styled from 'styled-components';
import {Col} from 'react-bootstrap';
import { RatingsContainer, TabsContainer } from "../WellnessPageV2/styles"
import {CustomListItem, MenuList} from '../NavigationBarV2/styles'
import Scurve from '/public/images/WellnessPage/scurve.svg';
import {MenuLinks} from '../NavigationBarV2/styles';
import { ImageUrl } from '../../utils/constants';
const BoldFont = 'Rubik-Medium';
const ExtraBoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';
const MontserratRegular = 'Montserrat-Regular';
const MontserratSemiBold = 'Montserrat-SemiBold';
const MontserratBold = 'Montserrat-Bold';
const backgroundUrl = `url(${ImageUrl}/images/HomePageV2/tailored-background.png)`;
const bannerBackground = `url(${ImageUrl}/images/HomePageV2/Home_banner_background.webp)`;

const Section = styled.div`
  margin: 0 auto;
  height: 600px;
  width: 100%;
  max-width: 1200px;
  display: block;
  font-family: ${RegularFont};
  display: ${({intelligenceSection}) => intelligenceSection ? 'block' : 'flex'};
  align-items: flex-start;
  position: relative;
  padding-top: 100px;
  margin-bottom: -70px;
  @media (max-width: 991px) {
    display: block;
  }
  @media (max-width: 1201px) {
    padding-top: 0px;
  }
  @media (max-width: 930px) {
    flex-flow: column;
    height: 1100px;
    padding-top: 30px;
  }
  @media(max-width: 700px){
    margin-bottom: 0;
    height: auto;
  }
`;

const   IntroTextContainer = styled.div`
  display: inline-block;
  float: left;
  width: 50%;
  letter-spacing: 1.5px;
  padding: 0px 10px 30px 0px;
  h1 {
     margin-bottom: 25px;
     font-family: ${BoldFont};
     letter-spacing: 0;
     margin-top: 0px;
     font-weight: 700;
     color: #FFFFFF;
     font-size: 43px;
     line-height: 120.5%;
     font-weight: 700;
     text-align: left;
     text-transform: capitalize;
  }
  p {
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 25px 0 35px 0;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 24px;
  }
  .mobile-top-paragraph{
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 20px 0 30px 0;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: 20px;
    // padding-right: 28px;
    text-align: justify;

font-family: Montserrat-Medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
text-align: justify;
    @media (min-width: 1200px){
      display: none;
    }
  }
  .wrap {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .divider{
    width: 75px;
    >img{
      width: 100%;
      height: 5px;
    }
  }
  @media (max-width: 1201px) {
    width: 80%;
    padding: 0px 10px 30px 28px; 
    
    h1{
      margin-bottom: 25px;
      font-family: ${BoldFont};
      letter-spacing: 0;
      margin-top: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: #FFFFFF;
      font-size: 36px;
      line-height: 50px;
      font-weight: 600;
      text-align: left;
    }
    p {
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 30px 0 30px 0;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 31px;
    display: none;
    }
    .wrap {
      width: 60%;
    }
  }
  @media (max-width: 930px) {
    width: 100%;
    .wrap {
      width: 45%;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
    text-align: left;
    >div:last-child{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  @media(max-width: 350px){
    >div:last-child{
      >button{
        width: 80%;
      }
    }
  }
  @media (max-width: 600px) {
      padding-top: 10px;
      height: 450px;
      .wrap {
        width: 100%;
      }
  }
  @media(max-width: 500px){
    padding: 10px;
    width: 350px;
    max-width: 750px;
    min-width: 350px;
    >h1{
      font-size: 30px;
      font-family: 'Rubik-Bold';
      font-style: normal;
      line-height: 40px;
      margin-left: 20px;
      margin-bottom: 20px;
    }
    .divider{
      margin-left: 20px;
    }
    >p{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-left: 20px;
      padding-right: 28px;
      text-align: justify;

font-family: Montserrat-Medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
text-align: justify;
display: none;
    }
  }
  @media (max-width: 350px) {
    .wrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  @media (min-width: 1420px) {
    h1 {
      max-width: unset;
      letter-spacing: 1px;
      text-align:unset;
      font-family: ${BoldFont};
      letter-spacing: 0;
      font-weight: 700;
      text-transform: uppercase;
      color: #FFFFFF;
      font-size: 48px;
      line-height: 110.5%;
      text-align: left;
      text-transform: capitalize;
    }
    p {
    line-height: 22px;
    font-family: Montserrat-Medium;
    letter-spacing: 0;
    font-weight: 500;
    margin: 25px 0 35px 0;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 24px;
    }
    .wrap{
      margin-top: 20px;
    }
  }
  @media (min-width: 1500px) {
    padding: 0px 10px 0px 0px;
  }
`;

const WrapperContainer = styled.div`
  width: 100%;
  float: left;
  background-color: #FFFFFF;
  padding-top: 70px
`;

const MainWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin-top: 150px;
  padding-bottom:70px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  >div:first-child{
    width:100%;
    justify-content:center;
  
    >p:first-child{
      color:#F6797D;
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0.06em;
      font-family: ${MontserratSemiBold};
    }
    >h1{
      margin-top:0;
      margin-top:20px;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-family: ${ExtraBoldFont}
    }
    >p{
      font-style: normal;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.06em;
      text-align: center;
      color: #243A5A;
      font-family: ${MontserratRegular}
      @media(max-width: 500px){
        letter-spacing: 0;
        text-align: justify;
        font-family: Montserrat-Regular;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 33px;
color: #24395A;
text-align:center;
      }
    }
    >img{
      margin-top: 19px;
      position:relative;
      left:47%;
    }
  }
  >div:last-child{
    width:100%;
    margin-top: 75px;
    display:flex;
    >div:first-child{
      text-align: right;
      width:50%;
      >img{
        width: ${({customWith}) => customWith ? '550px' : '550px'};
        height: ${({customWith}) => customWith ? 'auto' : 'auto'};
      }
    }
    .responsive-content{
      display:none;
      width:80%;
      margin: 40px auto;
      padding: 5px 10px 20px 10px;
      border-radius: 0px 22px 22px 22px;
      background-color: ${({bgResponsiveColor}) => bgResponsiveColor };
      >h1{
        color: ${({fontResponsiveColor}) => fontResponsiveColor };
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        font-family : ${BoldFont};
      }
      >p{
        color: #3A3A3A;
        @media(max-width: 500px){
          font-size: 15px;
          font-weight: 400;
          font-family: 'Montserrat-Regular';
        }
      }
    }
    >div:last-child{
      width:50%;
      padding: 0 0 0 30px;
      >div{
        >div:first-child{
          width: 10%;
        }
        >div:last-child{
          width: 70%;
        }
      }
    }
  }
  @media(max-width: 1000px){
    padding: 0;
    margin: 20px 0;
    >div:first-child{
      padding: 0 20px;
      >img{
        left: 43%;
      }
    }
    >div:last-child{
      margin-top: 50px;
      flex-direction: column-reverse;
        >div:first-child{
          width:100%;
          text-align: center;
          >img{
            width: 80%;
          }
        }
        .responsive-content{
          display:block;
          margin-top: 19px;
          margin-bottom: 15px;
        }
        >div:last-child{
          display: flex;
          width:100%;
          >div:first-child{
            flex-direction: column-reverse;
            >div:first-child{
              padding: 0;
              width: 100%;
              margin: auto 0;
            }
            >div:last-child{
              display: none;
            }
          }
        }
      }
  }
  @media(max-width: 600px){
    margin-top: 250px;
    >div:first-child{
      >p:first-child{
        letter-spacing: 0.08em;
        font-size: 16px;
        line-height: 20px;
      }
      >h1{
        font-size: 28px;
        line-height: 118.5%;
        font-family: Rubik-Bold;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 118.5%;
color: #24395A;
margin-bottom: 30px;
      }
      >p:last-child{
        font-size: 14px;
        line-height: 137.9%;
      }
      >img{
        left: 39%;
      }
    }
    >div:last-child{
      >div:last-child{
        padding-left: 50px;
        >div{
          width: 25%;
        }
      }
    }
  }
  @media(max-width: 500px){
    >div:last-child{
      >div:first-child{
        width: 98%;
        >img{
          width: auto;
          height: ${({customWith}) => customWith ? '263px' : '280px'};
          margin-left: 0px;
        }
      }
  }
`;

const CurveWrapper = styled.div`
    float: left;
    width: 100%;
    background-image: url(${Scurve});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    @media(max-width: 500px){
      display: none;
    }
`;

const PosterImage = styled.div`
  display: inline-block;
  float: left; 
  padding: 0 0px 0 0px;
  width: 60%;
  text-align: center;

  img {
    width: 100%;
    margin-bottom: -35px;
    max-height: 800px;
    min-height: 420px;
    position: relative;
    height: 500px;
    width: auto;
    float: right;
  }
  
  @media (max-width: 1201px) {
    width: 50%;
    padding: 0px;
    
    img {
      width: 100%;
      margin-bottom: -70px;
      min-height: unset;
      max-height: unset;
      max-height: 800px;
    min-height: 420px;
    position: relative;
    height: 450px;
    width: auto;
    top: 40px;
    float:right;
  }
  }
  
  @media (max-width: 1000px) {
    img {
    }
  }
  @media (max-width: 930px) {
    width: 100%;
    padding: 70px 30px 0px 30px;
  }
  @media (max-width: 768px) {
    padding: 30px 30px 0px 30px;
    img {
      top: 90px;
      position: relative;
      margin-bottom: -15px;
    }
  }
  @media (max-width: 600px) {
    padding: 30px 30px 0px 30px;
    img {
      top: 25px;
      position: relative;
      margin-bottom: -15px;
    }
  }
  @media (max-width: 500px) {
    img {
      margin-bottom: -50px;
      min-height: 250px;
      height: 200px;
      position: relative;
    margin: 0px;
    top: 202px;
    height: 200px;
    margin-bottom: 200px;
    left: -20px;
    top: 70px;
    }
  }
  @media (min-width:1400px){
    >img{
      max-height: 650px;
    }
  }
  @media(max-width: 350px){
    margin-top: 20px;
  }
`;

const PromotionalContainer = styled.div`
  width: 100%;
  min-height: 200px;
  background-image: linear-gradient(#E6F6FF, #CFEDFF);
  float: left;
  padding: 30px 90px 30px 98px;
  
 > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
      span, div {
            margin: 55px 15px 0px 15px;
            font-size: 16px;
            letter-spacing: 1.4px;
            color: #1e577a;
            font-family: ${BoldFont};
            text-align: center;
            font-weight: 700;
      }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;

const LogoContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height ? props.height : 'auto'};
  img {
    width: 100%;
    height: 100%;
  }
`;

const InformationContainer = styled.div`
  width: 100%;
  float: left;
  background-color: #F6F6F8;
  padding: 60px 0px 55px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ? props.marginTop : '0px'};
  h3 {
    font-family: ${MediumFont};
    margin-bottom: 15px
    font-size: 34px;
    font-weight: 700;
    color: #2b281e;
    text-align: center;
  }
  
  p {
    text-align: center;
    width: 50%;
    margin-top: 15px;
    font-family: ${RegularFont};
    font-size: 20px;
    color: #2b281e;
    font-weight: 400;
    max-width: 640px;
  }
  
  img {
    width: 100%;
    margin-top: 25px;
  }
  .grassRootImageContainer {
    text-align: center;
    img {
      width: 90%;
      margin-top: 60px;
    }
  }
  .grassRootText {
    width: 60%;
    max-width: unset;
  }
  @media (max-width: 1120px){
    padding: ${(props) => props.paddingBottom ? '60px 0px 0px 0px' : '60px 0px 55px 0px'};
    p {
      width: 60%;
    }
  } 
  @media (max-width: 1000px){
    padding: ${(props) => props.paddingBottom ? '60px 0px 0px 0px' : '60px 6vw 55px 6vw'};
    p, .grassRootText {
      width: 65%;
    }
  }
  @media (max-width: 785px){
    padding: 60px 0px 55px 0px;
  }
  @media (max-width: 600px) {
    p, .grassRootText {
      width: 80%;
    } 
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1600px) / 2);
    padding-right: calc(calc(100% - 1600px) / 2);
  }
  .tailorBlock {
    text-align: center;
    img {
      width: 65%;
    }
  }
`;

const PlatformInformation = styled.div`
  float: left;
  margin-top:${(props) => props.marginTop ? props.marginTop : '100px'};
  width: 100%;
  h4 {
    text-align: center;
    font-size: 26px;
    color: #2b281e;
    font-family: ${MediumFont};
    padding: 0px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 2px;
  }
  @media (max-width: 1000px){
    margin-top: 80px;
  }
`;

const PlatformItemContainer = styled.div`
    float: left;
    width: 100%;
    padding: 0px 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    @media (max-width: 1000px) {
      padding: 0px 6vw;
    }
    @media (max-width: 500px) {
      padding: 0px 10px;
    }
`;

const PlatformItem = styled.div`
    float: left;
    margin: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 230px;
    h4 {
        text-align: center;
        color: #2b281e;
        font-family: ${BoldFont};
        font-size: 22px;
        margin-top: 40px;
        font-weight: 700;
    }
    p {
        text-align: center;
        width: 100%;
        max-width: 232px;
        color: #2b281e;
        font-size: 14px;
        margin-top: 5px;
        font-weight: 400;
    }
    a {
       color: #f27c06;
       margin-top: 20px;
       cursor: pointer;
       font-weight: 700;
       font-size: 15px;
       font-family: ${BoldFont};
    }
    picture {
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      img {
        height: ${(props) => props.iconWidth ? '60px' : '100%'};
        width: ${(props) => props.iconWidth ? '60px' : '100%'};
      }
    }
    > div {
      text-align: center
    }
`;

const GrassRootItem = styled.div`
    float: left;
    margin: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 430px;
    justify-content: ${(props) => props.justifyContent ? props.justifyContent : 'space-between'};
    p {
      text-align: center;
      width: 100%;
      max-width: 210px;
      color: #2b281e;
      font-size: 15px;
      font-weight: 400;
      span {
        font-family: ${BoldFont};
      }
    }
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
    div {
      text-align: center
    }
    @media (max-width: 889px) {
      min-height: 380px;
    }
    @media (max-width: 522px) {
      min-height: unset;
    }
`;

const HealthContainer = styled.div`
  float: left;
  width: 100%;
  height: 600px;
  ${'' /* background: url(../../images/HomePageV2/tailored-background.png); */}
  background: ${backgroundUrl};
  background-size: 100% 100%;
  @media (min-width: 1400px) {
    height: 650px;
  }
  @media (max-width: 1201px) {
    height: 980px;
    display: flex;
    flex-direction: column;
    align-items: center;
   }
  @media (max-width: 768px) {
      height: 900px;
  }
  @media (max-width: 630px) {
      height: 950px;
  }
  @media (max-width: 500px) {
      height: 1200px;
  }
  
  @media (max-width: 400px) {
      height: 1300px;
  } 
  .roundItemContainer {
    width: 60%;
    height: 100%;
    float: right;
    position: relative;
    .roundContainer {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      display: flex;
      position: absolute;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 12px 0px;
       p {
        font-size: 12px;
        color: #ffffff;
        font-weight: 900;
        font-family: ${BoldFont};
        margin-bottom: 0px;
        margin-top: 10px;
       }
       img {
        position: static;
        opacity: 1;
       }
    }
    .first {
      background: #c464ac;
      top: 7%;
      left: 40%;
      img {
        width: 19px;
        height: 41px;
        opacity: 1;
      }
    }
    .second {
      background: #f4d463;
      bottom: 7%;
      left: 40%;
      img {
        width: 50px;
        height: 30px;
        opacity: 1;
      }
    }
    .third {
      background: #7164c4;
      top: 25%;
      left: 20%;
      img {
        width: 45px;
        height: 35px;
        opacity: 1;
      }
    }
    .fourth {
      background: #e48d8b;
      bottom: 25%;
      left: 20%;
      img {
        width: 41px;
        height: 45px;
        opacity: 1;
      }
    }
    .fifth {
      background: #4a9dc6;
      top: 25%;
      right: 22%;
      img {
        width: 50px;
        height: 25px;
        opacity: 1;
      }
    }
    .six {
      background: #8bbd53;
      bottom: 25%;
      right: 22%;
      img {
        width: 35px;
        height: 40px;
        opacity: 1;
      }
    }
    img {
      width: 100px;
      height: 80px;
      position: absolute;
      top: 40%;
      left: 42%;
      opacity: ${({opacity}) => opacity && '0.4'};
    }
    @media (min-width: 1400px) {
      .fifth, .six {
        right: 24%;
      }
    }
    @media (min-width: 1560px) {
      .fifth, .six {
        right: 26%;
      }
      img {
        left: 41.5%;
      }
    }
    @media (max-width: 1201px) {
        width: 100%;
        max-width: 768px;
        height: 70%;
      }
    @media (max-width: 768px){
        img {
              left: 40.5%;
           }
         .fifth, .six {
              right: 25%;
         }  
        .roundContainer {
           width: 110px;
           height: 110px;
           p {
              font-size: 11px;
           }
        }
    }  
    @media (max-width: 630px){
      .third, .fourth {
        left: 15%
      }
      .fifth, .six {
        right: 18%
      }
    }
    @media (max-width: 550px){
      .fifth, .six {
        right: 13%
      }
    }
    @media (max-width: 500px){
      .third, .fourth {
        left: 5%
      }
      .fifth, .six {
        right: 5%
      }
    }
    @media (max-width: 450px){
      .first, .second {
        left: 37%
      }
    }
    @media (max-width: 400px){
      img {
        left: 38.5%;
      }
    }
    @media (max-width: 350px){
      img {
        left: 36.5%;
      }
    }
    @media (max-width: 360px){
      .third, .fourth {
        left: 0%
      }
      .fifth, .six {
        right: 0%
      }
    }
  }
  
  .tailoredTextContainer {
    float: left;
    width: 40%;
    padding: 100px 0px 30px 85px;
    h1 {
      font-size: 32px;
      color: #fff;
      font-weight: 700;
      line-height: 35px;
      font-family: ${BoldFont};
      line-height: 40px;
      > span {
        margin-bottom: 22px;
        display: inline-block;
      }
    }
    p {
      font-family: ${RegularFont};
      font-size: 20px;
      color: #fff;
      padding-top: 40px;
      font-weight: 400;
    }
    @media (min-width: 1400px){
      h1{
        max-width: 425px;
        font-size: 34px;
      }
      p {
        max-width: 425px;
        font-size: 22px;
      }
    }
    @media (max-width: 1201px) {
        width: 100%;
        padding: 30px 90px 0px 98px;
        height: 30%;
        h1 {
          width: 55%;
        }
        p {
          padding-top: 0px;
        }
      }
   @media (max-width: 800px){
      h1 {
          width: 80%;
        }
   }
   @media (max-width: 600px){
      h1 {
          width: 90%;
        }
   }   
   @media (max-width: 1000px){
      padding: 30px 6vw;
    }   
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1600px) / 2);
    padding-right: calc(calc(100% - 1600px) / 2);
  }
`;

const GetStartedContainer = styled.div`
  float: left;
  width: 100%;
  padding: 80px 0px;
  background-image: linear-gradient(#05344D, #136680);
  display: flex;
  justify-content: center;
 > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      font-size: 15px;
      width: 240px;
    }
    h3 {
    color: #fff;
    font-size: 33px;
    line-height: 36px;
    text-align: center;
    font-family: ${RegularFont}
  }
  }
 
`;

const ImageContainer = styled.div`
  width: ${({fullWidth}) => fullWidth ? '80%' : '50%'};
  padding: ${({left}) => left ? '0' : '0 70px 0 15px'};
  margin: ${({fullWidth}) => fullWidth ? 'auto' : '0'};
  float: ${({fullWidth}) => fullWidth ? 'unset' : 'left'};

  @media (max-width: 1000px) {
    padding: ${({left}) => left ? '0' : '0 15px'};
  }
  
  > picture {
    > img {
      width: 100%;
    }
  }
  
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ButtonLearnMoree = styled.button`
margin-top: ${({noMargin}) => noMargin ? '0px' : '25px'};
width: 190px;
height: 60px;
// background: rgba(16, 43, 82, 0.17);
// color: #F6797D;
padding: 11px 10px;
border-radius: 3px;
margin-left: 5px;
// border: 2px solid #F6797D;;
font-size: 18px;
font-family: ${BoldFont};
font-weight: 500;
line-height: 21px;
text-align: center;
margin-left: 20px;
background: #F6797D;
color: #fff;
border: none;
&:hover, &:focus, &:active {
  outline: none;
}

@media (max-width: 350px) {
  width: 59%;
  padding: 14px 10px;
  z-inded: 5;  
}

@media (max-width: 720px) {
  width: 80%;
  width: 320px;
  height: 50px;
  z-inded: 5;
}

@media (min-width: 1420px) {
  font-size: 18px;
  padding: 15px 10px;
}
@media (min-width: 1201px) {
  width: 36%;
  width: 190px;
  font-size: 18px;
  padding: 10px 10px;
  z-index: 5;
}
@media (max-width: 435px) {
  margin-left:21px;
  z-index: 5
}
`;

const ButtonBookDemo = styled.button`
  margin-top: ${({noMargin}) => noMargin ? '0px' : '0px'};
  height: 45px;
  width: 36%;
  background-color: #F6797D;
  color: #fff;
  padding: 13px 10px;
  border-radius: 3px;
  border: none;
  font-size: 16px;
  box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
  font-family: Rubik-Medium;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  margin-top: 15px;
  &:hover, &:focus, &:active {
    outline: none;
  }
  @media (min-width: 1420px) {
    font-size: 16px;
    padding: 13px 10px;
  }
  @media (max-width: 500px) {
    width: ${({customWith}) => customWith ? '315px' : '200px'};
    padding: 14px 10px;
    font-size: 14px;
  }
`;

const ButtonBookDemoNew = styled.button`
  margin-top: ${({noMargin}) => noMargin ? '0px' : '0px'};
  height: 45px;
  width: 48%;
  background-color: #F6797D;
  color: #fff;
  padding: 13px 10px;
  border-radius: 3px;
  border: none;
  font-size: 16px;
  box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
  font-family: Rubik-Medium;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  margin-top: 15px;
  &:hover, &:focus, &:active {
    outline: none;
  }
  @media (min-width: 1420px) {
    font-size: 18px;
    padding: 15px 10px;
  }
  @media (max-width: 500px) {
    width: ${({customWith}) => customWith ? '315px' : '200px'};
    padding: 14px 10px;
    font-size: 14px;
  }
  @media (max-width: 435px) {
    margin-left:21px;
  }
`;


const Button = styled.button`
  margin-top: ${({noMargin}) => noMargin ? '0px' : '25px'};
  width: 37%;
  background-color: #1573B9;
  color: white;
  padding: 13px 10px;
  border-radius: 40px;
  border: none;
  font-size: 13px;
  font-family: ${BoldFont};
  font-weight: 700;
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 350px) {
    width: 59%;
    padding: 14px 10px;
  }
  
  @media (min-width: 1420px) {
    font-size: 15px;
    padding: 15px 10px;
  }
`;

const VideoButton = styled.button`
  width: 59%;
  background-color: transparent;
  color: #1573B9;
  padding: 13px 10px;
  border-radius: 40px;
  border: 1px solid #1573B9;
  font-size: 13px;
  font-family: ${BoldFont};
  font-weight: 700;
  display: none;
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  i {
    margin-right: 10px;
  }
  @media (max-width: 350px){
    margin-top: 15px;
  }
  @media (min-width: 1420px) {
    font-size: 15px;
    padding: 15px 10px;
  }
  @media (max-width: 500px) {
    width: 315px;
    margin-left: 2px;
  }
`;

const Button2 = styled.button`
  width: ${(props) => props.tryFree ? '200px' : '236px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#F6797D' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '10px 10px'};
  border-radius: 3px;
  border: none;
  font-size: 20px;
  font-family: Rubik-Medium;
  line-height: 24px;
  font-style: normal;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  float: left;
  text-align: center;
`;

const ImageWrapper = styled(Col)`
  width: 100%;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  > img {
    width: 100%;
    max-width: 450px;
    cursor: pointer;
  }
`;

const GlobalMainWrapper = styled.div`
  width:100%;
  max-width: 1200px;
  margin-top: 80px;
  display:flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  >div:first-child{
    width:100%;
    text-align:center;
    >p{
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #69C2FF;
      font-style: normal;
      font-weight: 600;
      font-family: ${MontserratSemiBold};
    }
    >h1{
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #24395A;
      text-transform: capitalize;
      font-family: ${ExtraBoldFont};
    }
  }
  >div:last-child{
    display: flex;
    flex-direction : row;
    padding: 50px 0px 0px 0px;
    justify-content: center;
    >div{
      width:18%;
      text-align: center;
      >img{
        width: 136px;
        height: 153px;
      }
    }
    @media(min-width: 1000px){
    }
  }
  @media(max-width: 1000px){
    >div:last-child{
      display: flex;
      flex-wrap: wrap;
      padding: 0 40px;
      justify-content: center;
      >div{
        margin: 40px 15px;
        width: auto
      }
    }
  }
  @media(max-width: 700px){
    padding: 0px 8px;
    margin-top: 150px;
    >div:first-child{
      >p{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.08em;
      }
      >h1{
        font-size: 28px;
        line-height: 31px;
        letter-spacing: 0.03em;
        font-family: Rubik-Bold;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 31px;
color: #24395A;
margin-bottom: 30px;
      }
    }
    >div:last-child{
      padding: 0;
      // margin-top: 50px;
      >div{
        font-size: 15px;
        width: 90px;
        height: 102px;
        margin: 20px 9px 30px 9px;
        >img{
          width: 100%;
          height: 100%;
        }
      }

    }
  }
`;

const ShowLogos = styled.div`
  width:100%;
  max-width: 1200px;
  display:flex;
  float: left;
  padding: 25px 0px 0 0px;
  margin: 20px auto;
  justify-content: space-around;
  flex-wrap: wrap;
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 147px;
      height: 51px;
    }
  }
  >div:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 133px;
      height: 51px;
    }
  }
  >div:nth-child(3){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 193px;
      height: 51px;
    }
  }
  >div:nth-child(4){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 119px;
      height: 51px;
    }
  }
  >div:nth-child(5){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 125px;
      height: 51px;
    }
  }
  @media(max-width: 1200px){
    padding: 0 10px;
    >div{
      width: auto;
      margin: 20px;
    }
  }
  @media (max-width: 700px){
    position: relative;
    justify-content: space-between;
    >div:first-child{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      >img{
        width: 125px;
        height: 36px;
      }
    }
    >div:nth-child(2){
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      >img{
        width: 116px;
        height: 58px;
      }
    }
    >div:nth-child(3){
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      >img{
        width: 125px;
        height: 59px;
      }
    }
    >div:nth-child(4){
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      >img{
        width: 119px;
        height: 49px;
      }
    }
    >div:nth-child(5){
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      >img{
        width: 193px;
        height: 48px;
      }
    }
  }
  @media (max-width: 500px){
    padding: 0;
    align-items: center;
    justify-content: center;
    // background-color: #F5FBFF;
    // margin-top: 30px; 
    margin: 0px;
    >div:first-child{
      font-size: 15px;
      margin: 20px 7px;
      >img{
        width: auto;
         height: 100%;
         height: 36px;
      }
    }
    >div:nth-child(2){
      font-size: 15px;
      margin: 20px 26px;
      >img{
        width: auto;
        height: 100%;
        height: 36px;
      }
    }
    >div:nth-child(3){
      font-size: 15px;
      width: 102px;
      // height: 115px;
      margin: 20px 7px;
      >img{
        width: auto;
        height: auto;
        height: 36px;
      }
    }
    >div:nth-child(4){
      font-size: 15px;
      width: 102px;
      // height: 115px;
      margin: 20px 7px;
      >img{
        width: auto;
        height: auto;
        height: 36px;
      }
    }
    >div:nth-child(5){
      font-size: 15px;
      width: 102px;
      // height: 115px;
      margin: 20px 7px;
      >img{
        width: auto;
        height: auto;
        height: 36px;
      }
    }
  }
  }
`;

const CompanyMainWrapper = styled.div`
  width:100%;
  display:flex;
  // height: 307px;
  flex-direction:column;
  // background-color: #F5FBFF;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  margin-bottom: 55px;
  >div:first-child{
    width:100%;
    text-align: center;
    padding-top: 75px;
    @media (max-width: 500px){
      padding-top: 30px;
    }
    >p{
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.08em;
      color: #243954;
      font-family: 'Rubik-Medium';
      >span{
        color:#F6797D;
        font-family: ${MontserratBold}
      }   
     @media(max-width: 435px) {
      font-size: 18px;
      // padding: 0px 40px;
      line-height: 24px;
      padding-left: 30px;
      padding-right: 30px;
     }
    }
  }
  @media(max-width: 1200px) {
    margin-top: -40px;
    height: auto;
  }
  @media(min-width: 1420px) {
    padding-top: 0px;
  }
  @media(min-width: 1500px) {
    padding-top: 0px;
  }
  @media(min-width: 1600px) {
    padding-top: 0px;
  }
`;

const WellBeingMainWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: block;
  font-family: ${RegularFont};
  display: ${({intelligenceSection}) => intelligenceSection ? 'block' : 'flex'};
  align-items: flex-start;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  flex-direction: row;
  height:600px;
  justify-content: center;
  height: auto;
  >div:first-child{
    width:600px;
    text-align:center;
    >img{
      padding-top: 60px;
      width: 600px;
      padding-right: 30px;
    }
  }
  >div:last-child{
    width:600px;
    >div:first-child{
      padding-left: 90px !important;
      padding-top: 170px;
      padding-right: 88px;
      >p{
        font-style: normal;
        font-size: 24px;
        padding-bottom: 35px;
        line-height: 29px;
        text-transform: uppercase;
        color: #F6797D;
        margin-bottom: 0;
        padding-bottom: 0;
        font-family: ${MontserratSemiBold};
      }
      >h1{
        font-family: 'Rubik-Bold';
        font-style: normal;
        font-size: 30px;
        line-height: 42px;
        text-transform: capitalize;
        color: #FFFFFF;
        font-weight: bold;
        margin-bottom: 0;
        margin: 0px;
        font-family:
      }
      >div:first-child{
        font-weight: normal;
        font-size: 18px;
        color: #FFFFFF;
        margin-bottom: 0;
        line-height: 26px;  
        font-family: ${MontserratRegular};
      }
      >div:last-child{
        font-weight: normal;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 26px;
        margin: 40px 0;
        font-family: ${MontserratRegular};
      }
      >img{ 
        margin: 30px 0;
      }
    }
  }
  ${'' /* @media(min-width: 1400px){
    >div:last-child{
      >div{
        padding-left: 240px;
      }
    }
  } */}

  @media(max-width: 1000px){
    margin-top: 21px;
    display: flex;
    height: auto;
    flex-direction: column-reverse;
    float: left;
    background-image: none;
    >div:first-child{
      width: 100%;
      >img{
        height:304px;
        width: auto;
        padding:30px 0px;
      }
    }
    >div:last-child{
      background: rgba(0, 0, 0, 0) linear-gradient(rgb(16, 43, 82) 0%, rgb(0, 111, 188) 100%) repeat scroll 0% 0%;;
      width: 100%;
      text-align: center;
      >div{
        padding-top: 60px;
        padding-left: 0 !important;
        >p{
          padding-bottom: 20px;
        }
        >img{
          margin-bottom: 40px;
        }
        >button{
          margin: 30px 0;
        }
      }
    }
  }
  @media(max-width: 500px){
    >div:nth-child(2){
      >div{
        >p:first-child{
          font-size: 16px;
          line-height: 20px;
        }
        >img{
          margin-top: 0;
        }
        >h1{
          font-size: 28px;
          line-height: 33px;
        }
        >p:last-child{
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
    >div:last-child{
      width:100%;
      >div:first-child{
        padding-left: 0px !important;
        padding-top: 60px;
        padding: 60px 0 0 0;
        >h1{
          font-family: Rubik-Bold;
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 33px;
        }
        >div:first-child{
          font-weight: normal;
          font-size: 18px;
          color: #FFFFFF;
          margin-bottom: 0;
          line-height: 26px;  
          text-align: justify;
          font-family: ${MontserratRegular};
        }
      }
      
  }
`;

const RatingsContainers = styled(RatingsContainer)`
    max-width: 1200px;
    .ratingStars {
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 80px 0 10px 0;
    }
    .ratingDescription{
      padding: 0px 50px 5px;
      margin: 13px 13px 0px 13px;
      height: 150px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #3a3a3a;
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: ${MontserratRegular};
    }

    .ratingBy{
      margin-top: 30px;
      span{
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #3a3a3a;
      }
    }
    @media(max-width: 700px){
      .ratingBy{
        font-family: 'Rubik-Bold';
        font-style: bold;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #3A3A3A;
        text-align: center;
        display: block;
        >span{
          display: block;
          font-family: Montserrat-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #3A3A3A;
        text-align: center;
        }
    }
  }
    @media(min-width: 1500px) {
      .ratingDescription {
        height: 100px;
      }
    }
    @media(min-width: 1420px) {
      .ratingDescription {
        height: 120px;
      }
    }

    @media(max-width: 1000px){
      >img{
        left: 43%;
      }
      .image{
        >img{
        left: 43%;
      }
      }
      .ratingDescription{
        font-size: 16px;
      }
    }
    @media(max-width: 700px){
      .ratingDescription{
        font-size: 13px;
        line-height: 18px;
        height: 190px;
        padding: 0px 20px 5px;
      }
      >img{
        left: 38%;
      }
      .image{
        >img{
        left: 38%;
      }
      }
    }
    @media(max-width: 650px){
      .ratingDescription{
        line-height: 20px;
      }
      >img{
        left: 39%;
      }
      .image{
        >img{
        left: 39%;
      }
      }
    } 
    @media(max-width: 450px){
      .ratingDescription{
        font-size: 14px;
        text-align: justify;
        font-family: Montserrat-Regular;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 22.61px;
text-align: center;
      }
      >img{
        left: 36%;
      }
      .image{
        >img{
        left: 36%;
      }
      }
    }
`;

const CustomListItemV2Main = styled(CustomListItem)`
  display: flex;
  width: auto;
  justify-content: center;
  margin: 0;
  border-top: ${({ active }) => active ? '5px solid #69C2FF' : '5px solid transparent'};
  border-bottom: none;
  padding-top: 15px;
  padding-bottom: 0px;
  margin: 0px 40px 0px 45px;
  >a{
    font-size: 20px;
    font-family: 'Rubik-Medium';
    line-height: 24px;
    text-align: center;
    font-weight: normal;
    color: ${({ active }) => active ? '#69C2FF' : '#9C9C9C'};
  }
  &:hover, &:focus, &:active {
    border-bottom: none;
    >a{
      font-size: 20px;
      color: #69C2FF;
    }
  }
  @media (max-width: 1200px) {
    min-width: unset;
    border-left: none;
    >a{
      font-size: 16px;
    }
    &:hover, &:focus, &:active {
      border-left: none;
      border-bottom: none;
      >a{
        font-size: 16px;
      }
  }
  }
`;

const MenuListV2Main = styled(MenuList)`
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 1200px) {
    position: unset;
    width: 100%;
    box-shadow: none;
    background-color: none;
    padding: 0 0 10px;
  }
`;

const TeamWrapper = styled.div`
  margin: 50px 0;
  width:100%;
  background-image: url("/public/images/NewHomePageV2/team.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  >div:first-child{
    width:33%;
  }
  >div:last-child{
    width:33%;
  }
  >div{
    width:40%;
    text-align: center;
    >h2{
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      color: #69C2FF;
      font-family: ${MontserratSemiBold}
    }
    >h1{
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 35px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0;
      color: #24395A;
    }
    .main-content{
      font-style: normal;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #3A3A3A;
      font-family: ${MontserratRegular}
    }
    .content{
      font-style: normal;
      font-family: ${MontserratRegular}
      font-size: 15px;
      line-height: 28px;
      text-align: center;
      color: #3A3A3A;
      >img{
        margin-right: 5px;
      }
      >span{
        font-family: Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #24395a;
      }
    }
  }
  @media(max-width: 720px){
    background-image: none;
    >div:first-child{
      width:0;
    }
    >div:nth-child(2){
      width:100%;
    }
    >div:last-child{
      width:0;
    }
  }
`;

const ButtonTeam = styled.button`
 margin-top: ${({noMargin}) => noMargin ? '0px' : '25px'};
width: 37%;
background: rgba(16, 43, 82, 0.17);
color: #69C2FF;
padding: 13px 10px;
background-color: white;
border-radius: 3px;
margin-left: 5px;
border: 2px solid #69C2FF;;
font-size: 13px;
font-family: ${BoldFont};
font-weight: 700;

&:hover, &:focus, &:active {
  outline: none;
}

@media (max-width: 350px) {
  width: 59%;
  padding: 14px 10px;
}

@media (min-width: 1420px) {
  font-size: 15px;
  padding: 15px 10px;
}
`;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
  max-width: 1200px;
  margin-top: 75px;
  >p{
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #F6797D;
    font-family: ${MontserratSemiBold};
  }
  >h1{
    font-style: normal;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #253A5B;
    font-family: ${ExtraBoldFont};
    text-transform: capitalize;
    padding-right: 8px;
  }
  @media(max-width: 500px){
    >h1{
      font-size: 28px;
      margin:28px
  }
`;

const TestimonialWrapper = styled.div`
  width: 100%;
`;

const RatingWrapper = styled.div`
  width:100%;
  max-width: 1200px;
  padding : 150px 0px 0px s0px;
  margin-left: auto;
  margin-right: auto;
  @media(max-width:1000px){
    padding: 0;
  }
  @media(max-width: 500px){
    // margin-top: 120px;
  }
`;

const CustomList = styled(CustomListItem)`
    width: auto;
    display: flex;
    justify-content: center;
    color: #69c2ff;
    margin: 0px 52px 0px 52px;
    border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '0px solid #EDEDED'};
    position: relative;
    top: 3px;
    >a{
      font-family : 'Rubik-Medium';
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      font-weight: normal;
      color: ${({active})=>active ? '#69c2ff' : "#9C9C9C" };
    }
    &:hover, &:focus, &:active {
      color: #69c2ff;
      border-bottom: 5px solid #69c2ff;
      > a {
        color: #69c2ff;
      }
    }
    @media(max-width: 1200px) {
      margin: 0px 2%;
      border-left: none;
      border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '0px solid #EDEDED'};
      &:hover, &:focus, &:active {
        border-left: none;
        border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '3px solid #EDEDED'};
      }
    }
    ul > li:hover {
      color: #69c2ff;
    }
`;

const MenuLinkss = styled(MenuLinks)`
font-family: 'Rubik-Medium';
font-size: 20px;
line-height: 24px;
text-align: center;
letter-spacing: -0.025em;
color: green;
`;

const CoreWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 150px 0 130px 0;
  margin-left: auto;
  margin-right: auto;
  >div:first-child{
    text-align:center;
    margin-bottom: 75px;
    >p{
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      color: #69C2FF;
      font-family: ${MontserratSemiBold}
    }
    >h1{
      font-family: ${ExtraBoldFont};
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
  }
  >div:nth-child(3){
    width:100%;
    display: flex;
    margin-top: 50px;
    >div:first-child{
      width:50%;
      padding: 0 7px 50px;
      >h1{
        font-family: ${BoldFont};
        font-size: 30px;
        line-height: 54px;
        letter-spacing: 0;
        color: #69c2ff;
        margin: 0;
      }
      >h2{
        font-family: ${MontserratSemiBold};
        margin: 0px 0 30px;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        color: #24395a;
        text-transform: uppercase;
      }
      >img{
        margin-bottom: 30px;
      }
      >div{
        height: auto;
        >p{
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #3A3A3A;
          font-family: ${MontserratRegular};
          margin-bottom: 0px;
          margin: 0px 0px 10px 0px
        }
      }
    }
    >div:last-child{
      width:50%;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      >img{
        position: relative;
        top: -40px;
        width: 460px;
        height: auto;
        left: 30px;
      }
    }
  }
  .responsive-core{
    display: none;
    width: 100%;
    padding: 0px 70px 0px 110px;
    flex-direction: column;
    >div{
      width: 100%;
      padding: 0;
      >div:first-child{
        display: flex;
        width: 100%;
      }
      >div:nth-child(2){
        width: 100%;
        >div:first-child{
          margin-bottom: 60px;
          width: 100%;
        }
        >div:last-child{
          width: 100%;
          margin-left: -10px;
          text-align: center;
          display: flex;
          justify-content: center;
          >img{
            width: auto;
            height: 450px;
          }
        }
      }
      >div:last-child{
        width:100%;
        text-align:center;
      }
    }
  }
  @media(max-width:1000px){
    .responsive-core{
      >div{
     
          >div:nth-child(2){
            >div:first-child{
              margin-bottom: 20px;
            }
    
        }
      }
    }
    >div:nth-child(3){
      >div:first-child{
        padding: 0 50px;
        >button{
          margin-top: 30px;
          width: 60%;
        }
      }
      >div:last-child{
        >img{
          width:80%;
        }
      }
    }
  }
  @media(max-width: 720px){
    margin: 0;
    .responsive-core{
      display: flex;
      >div{
        .selectedCoreContent{
          margin-left: 10px;
          padding: 5px;
          >div:first-child{
            >button{
              margin-top: 30px;
            }
          }
        }
        .coreDivider{
          >img{
            width: 100%;
          }
        }
      }
    } 
    >div:nth-child(2){
      display:none;
    }
    >div:nth-child(3){
      display:none;
    }
  }
  @media(max-width: 500px){
    margin-top: 60px;
    padding: 0px 20px;
    >div:first-child{
      >p{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.08em;
      }
      >h1{
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.03em;
        margin-bottom: 30px;
        font-family: Rubik-Bold;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 36px;
text-align: center;
letter-spacing: 0.03em;
text-transform: capitalize;
color: #24395A;
      }
    }
    .responsive-core{
      padding: 0 10px;
      padding-top: 30px;
      >div{
        >div{
          h1{
            font-size: 20px;
            letter-spacing: -0.025em;
            line-height: 24px;
            font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
text-align: left;
letter-spacing: -0.025em;
          }
        }
      }
      .selectedCoreContent{
        >div:first-child{
          >h2{
            font-size: 16px;
            line-height: 20px;
            margin-top: 0;
            font-family: Montserrat-Medium;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
          }
          >div{
            >p{
              line-height: 18px;
              margin-top: 33px;
              font-family: Montserrat-Regular;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
text-align: left;
            }
          }
        }
      }
    }
  }
`;

const TabsContainerV2 = styled(TabsContainer)`
  @media(max-width: 720px){
    display:none;
  }
`;

const TabsContainerTestimonial = styled(TabsContainer)`
  padding: 0px 0px 50px 0px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @media(max-width: 1000px){
    display:none;
  }
`;

const MenuListV2Main2 = styled(MenuList)`
display: flex;
align-items: center;
width: 100%;
justify-content: center;
border-bottom: 3px solid #EDEDED;
@media (max-width: 1200px) {
  position: unset;
  width: 100%;
  box-shadow: none;
  background-color: none;
}`;

const ProductWrapper = styled.div`
  display: flex;
  cursor: pointer;
  .bullet-child {
	  width: 20%;
    padding-top: ${({selected}) => selected ? '0px' : '20px'};
    min-width: 120px;
    .bullet {
      width: ${({selected}) => selected ? '74px' : '24px'};
      margin: 0 auto;
      background-color: white;
      border: ${({fontColor}) =>  `1px solid ${fontColor}`};
      height: ${({selected}) => selected ? '74px' : '24px'};
      border-radius: 50%;
      .inner-bullet {
        background: ${({backColor}) => backColor};
        margin: ${({selected}) => selected ? '5px' : '2px' };
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({selected}) => selected ? `calc(100% - 10px)` : `calc(100% - 4px)`};
        height: ${({selected}) => selected ? `calc(100% - 10px)` : `calc(100% - 4px)`};
        border-radius: 50%;
        text-align: center;
        >img{
          width: ${({imageWidth}) => imageWidth ? imageWidth : 'auto'};
          height: ${({imageHeight}) => imageHeight ? imageHeight : '55.59px'};
          padding: 10%;
        }
      }
    }
    .vertical-line {
      width: ${({show}) => show!==3 ? '2px' : '0'};
      background-color: ${({show}) => show!==3 ? '#EEEEEE' : 'white'};
      height: ${({show, selected}) => show!==3 ?  selected ? 'calc(100% - 50px)' : '100%'  : '0'};
      margin: ${({show}) => show!==3 ? '0 auto' : '0'};
    }
  }
  
  .content {
	  width: 80%;
    margin-bottom: 10px;
    padding: 5px 10px 20px 0;
    padding-left : ${({selected}) => selected ? '30px' : '10px'};
    margin-left: ${({selected}) => selected ? '10px' : '0'};
    border-radius: 0px 22px 22px 22px;
    background: ${({selected, bgColor}) => selected ? bgColor : '#fff'};
    >h1{
      color: ${({selected, fontColor}) => selected ? fontColor : '#3A3A3A'};
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      font-family : ${BoldFont};
    }
    >p{
      color: ${({selected}) => selected ? '#3A3A3A' : '#9c9c9c'};
      font-size: 16px;
      line-height: 26px;
      font-family : ${MontserratRegular};
    }
  }

  
  @media(max-width: 1000px){
    width:100%;
    .bullet-child{
      width: 100%;
      display: flex;
      padding: 0;
      margin: auto 0;
      .bullet{
        margin: auto 0;
        width: ${({selected}) => selected ? '70px' : '20px'};
      }
      .vertical-line{
        display: flex;
        margin: auto 0;
        width: 100%;
        height: 2px;
      }
    }
    .content{
      display:none;
      width: 100%;
      margin-bottom: 50px;
    }
  }
  @media(max-width: 930px) {
    .bullet-child {
      .bullet {
        margin: unset;
        width: ${({selected}) => selected ? '50%' : '28px'};
      }
    }
  }
  @media(max-width: 700px) {
    .bullet-child {
      width: 100%;
      display: flex;
      padding: 0;
      margin: auto 0;
      .bullet {
        width: ${({selected}) => selected ? '76px' : '28px'};
        margin: unset;
        background-color: white;
        border: ${({fontColor}) =>  `1px solid ${fontColor}`};
        height: ${({selected}) => selected ? '50px' : '24px'};
        border-radius: 50%;
        .inner-bullet {
            margin: ${({selected}) => selected ? '5px' : '2px' };
        }
      }
    }
  }
`;

const Divider = styled.div`
  margin:30px 0;
  width: 100%;
  text-align: center;
  @media(max-width:500px){
    height: 85px;
    >svg{
      width: 100%;
      height: 100%;
    }
  }
`;

const ButtonBookDemoo = styled.button`
margin-top: ${({noMargin}) => noMargin ? '0px' : '25px'};
width: 190px;
height: 60px;
// background-color: #F6797D;
color: #fff;
padding: 13px 10px;
// border: none;
font-size: 18px;
box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
font-family: ${BoldFont};
font-weight: 500;
line-height: 21px;
text-align: center;
text-transform: uppercase;
font-style: normal;
background: rgba(16,43,82,0.17);
border: 2px solid #FFFFFF;
border-radius: 3px;
.white_triangle{
  margin-right: 5%;
}

&:hover, &:focus, &:active {
  outline: none;
}

@media (max-width: 720px) {
  width: 80%;
  margin-bottom: 10px;
  width: 320px;
  height: 50px;
}

@media (max-width: 350px) {
  width: 59%;
  padding: 14px 10px;
}

@media (min-width: 1420px) {
  font-size: 18px;
  padding: 15px 10px;
}
@media (min-width: 1201px) {
  width: 36%;
  width: 190px;
  font-size: 18px;
  padding: 10px 10px;
}

@media (max-width: 435px) {
  margin-bottom: 14px;
  margin-left:21px;
}

`;

const CoreList = styled.div`
  width:100%;
  padding: 0;
  >div:first-child{
    cursor: pointer;
    >h1{
      width: 90%;
      color: ${({active})=>active ? '#F6797D' : "#24395A" };
      font-family: ${({active})=>active ? `${ExtraBoldFont}` : `${MediumFont}`};
      margin-left: 15px;
    }
    >h3{
      width: 90%;
      color: ${({active})=>active ? '#F6797D' : "#24395A" };
      font-family: ${({active})=>active ? `${ExtraBoldFont}` : `${MediumFont}`};
      margin-left: 15px;
    }
    >img{
      height: 10%;
      margin: auto 0;
      transform: ${({active}) => active && 'rotateX(180deg)'};
      margin-right: 15px;
    }
  }
`;

const ResponsiveTestimonialTab = styled.div`
  display:none;
  margin-bottom: 150px;
  @media(max-width: 1000px){
    float: left;
    width: 100%;
    display: flex;
    margin-bottom: 50px;
    .leftArrow{
      cursor: pointer;  
      width: 30%;
      justify-content: center;
      display: flex;
      >img{
        margin: auto 0;
      }
      @media(max-width: 500px){
        justify-content: flex-start;
        padding-left: 20px;
      }
    }
    >div:nth-child(2){
      width: 40%;
      text-align: center;
      border-top: 5px solid #F6797D;
      padding: 10px;
      @media(max-width: 500px){
        width: 45%;
      }
      >p{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #F6797D;
        font-family:'Montserrat-Bold';
        margin-top: 10px;
      }
    }
    >.rightArrow{
      cursor: pointer;
      width: 30%;
      justify-content: center;
      display: flex;
      >img{
        margin: auto 0;
      }
      @media(max-width: 500px){
        justify-content: flex-end;
        padding-right: 20px;
      }
    }
  }
`;

const Banner = styled.div`
  background-image: ${bannerBackground};
  background-size : cover;
  background-repeat : no-repeat;
  background-size: 100% 600px;
  position: relative;
  @media (max-width: 930px) {
    background-size : contain;
    background-size : 100% 100%;
    float: left;
  }
  @media(max-width: 700px){
    background-image: url('/public/images/HomePageV2/Home_banner.png');
    background-size : 100% 100%;
    max-height: 830px;
  }
`;
const BannerCurve = styled.div`
  background-image: url('/public/images/HomePageV2/testcurve.png');
  background-size : cover;
  height: 650px;
  background-repeat : no-repeat;
  background-size: 100% 650px;
  position: relative;
  @media (max-width: 930px) {
    background-size : contain;
    background-size : 100% 100%;
    float: left;
  }
  @media(max-width: 700px){
    background-image: url('/public/images/HomePageV2/Home_banner.png');
    background-size : 100% 490px;
    height: auto;
  }
`;

const Support = styled.div`
font-weight: 400;
font-size: 18px;
color: #FFFFFF;
margin-bottom: 0;
line-height: 26px;
font-style: normal;
font-family: ${MontserratRegular}
@media(max-width: 500px){
  padding: 20px;
  font-family: Montserrat-Regular;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
padding-bottom:0px;
}
`;

const SubSupport = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 26px;
  margin: 10px 0px 40px 0px;
  font-style: normal;
  font-family: ${MontserratRegular}
  @media(max-width: 500px){
    padding: 20px;
    font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  padding-bottom:0px;
  margin-bottom:0px;
  }
`;
const AwardSection = styled.div`
  width: 100%;
`;

const WellBeing = styled.div`
  width:100%;
  max-width: 1200px;
  display:flex;
  height: 400px;
  padding: 0 40px 0p 40px;
  background-repeat: no-repeat;
  border-radius: 12px;
  justify-content: center;
  margin: 150px auto 70px auto;
  @media(max-width: 500px){
    display: none;
  }
  @media(max-width: 780px){
    display: none;
  }
  >div{
    width:50%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 400px;
  }
  >div:first-child{
    justify-content:flex-start;
    height: 400px;
    background: ${({background})=>background ? `${background}` : `#F6B479`};
    border-radius: 16px 0px 0px 16px;
    display: block;
    @media(max-width: 500px){
      display: none;
    }
    >div:first-child{
    >p{
      left: 258px;
      display: flex;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 42px;
      line-height: 49px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 61px 0px 30px 75px;
      @media(max-width: 500px){
        display: none;
      }
    }
  }
}
>div:last-child{
  display: flex;
  border-radius: 0px 16px 16px 0px;
  background: ${({background})=>background ? `${background}` : `#F6B479`};
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    @media(max-width: 500px){
      display: none;
    }
    >img{
      width: auto;
      height: 370px;
      position: relative;
      top: 24px;
    }
}
@media (max-width: 500px){
  width:100%;
  display: grid;
  height: 400px;
  padding: 0 80px 0p 80px;
  background-repeat: no-repeat;
  border-radius: 12px;
  justify-content: center;
  margin: 160px auto 160px auto;
  margin-bottom: 500px
  >div{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 300px;
  }
  >div:first-child{
    width: 100%;
    justify-content:flex-start;
    height: 300px;
    background: #F6B479;
    display: block;
    >div:first-child{
    >p{
      left: 258px;
      display: flex;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 36px;
      line-height: 40px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 61px 0px 30px 75px;
      width: 100%;
      padding: 50px 0px 11px;
      text-align: center;
    }
  }
}
>div:last-child{
  height: 400px;
  display: flex;
  background: #F6B479;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    top: -2px;
    position: relative;
    >img{
      width: 350px;
    height: auto;
    position: relative;
    top: 65px;
    }
}
}
@media (max-width: 780px){
  width:100%
  display: grid;
  height: 400px;
  padding: 0 80px 0p 80px;
  background-repeat: no-repeat;
  border-radius: 12px;
  justify-content: center;
  margin: 40px auto 160px auto;
  margin-bottom: 150px
  >div{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 300px;
  }
  >div:first-child{
    width: 100%;
    justify-content:flex-start;
    height: 300px;
    background: #F6B479;
    display: block;
    max-width: 750px;
    width: 700px;
    min-width: 500px;
    >div:first-child{
    >p{
      left: 258px;
      display: block;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 36px;
      line-height: 40px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 61px 0px 30px 92px;
      width: 100%;
      padding: 50px 0px 11px;
      text-align: center;
    }
  }
}
>div:last-child{
  height: 260px;
  display: flex;
  background: #F6B479;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    top: -2px;
    position: relative;
    >img{
      width: 315px;
    height: auto;
    position: relative;
    top: 10px;
    }
}
}
`;

const ButtonGetStarted = styled.button`
  width: ${(props) => props.tryFree ? '200px' : '330px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#253A5B' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '15px 0px'};
  border-radius: 3px;
  border: none;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-style: normal;
  margin-left: 75px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  margin-bottom: 20px;
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
  @media (max-width: 780px) {
    margin: auto;
  }
  @media (max-width: 500px) {
    width: 315px;
    margin: auto;
  }
`;

const AboutWolibaWrapper = styled.div`
  width:100%;
  display:flex;
  height: 900px;
  flex-direction:column;
  background-color: #F5FBFF;
  @media (max-width: 1200px){
    margin-top: 50px;
    height: auto;
    padding-left: 25px;
    padding-right: 25px;
  }
  .About-uppersection{
    width:100%;
    text-align: center;
    padding-top: 75px;
    @media (max-width: 1200px){
      padding-top: 40px;
    }
    .section-heading{
      font-family: 'Montserrat-Medium';
      font-style: normal;
      font-size: 24px;
      line-height: 30px;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      text-transform: capitalize; 
      color: #24395A; 
      @media (max-width: 1200px){
        font-size: 20px;
      }
    }
    .middle-info{
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      text-transform: capitalize; 
      color: #24395A;
      margin-top: 20px;
      @media (max-width: 1200px){
        font-size: 30px;
        line-height: 40px;
        margin-top: 16px;
      }
    }
    .last-info{
      font-family: 'Montserrat-Regular';
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #24395A;
      margin-top: 20px;
      @media (max-width: 1200px){
        display: none;
      }
    }
  }
  `;

const WheelWrapperNew  = styled.div`
width: 100%;
max-width: 1200px;
margin: auto;
height: 500px;
display: flex;
justify-content: center;
flex-wrap: wrap;
@media (max-width: 1200px){
  flex-wrap: wrap;
  height: auto;
}
.wheel-section{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px){
    width: 100%;
    margin: 30px;
  }
}
.outer-circle{
  width: 500px;
  height: 500px;
  border-radius: 50%;
  border: ${(props) => props.wheelBtnBg == 'enhance employee culture and company experience' ? 'solid #9FC989 39px':'solid rgb(159 201 137 / 40%) 39px'};
  display: flex;
  justify-content: center;
  align-items: center; 
  @media (max-width: 1200px){
    width: 300px;
    height: 300px;
  }
}

.inner-circle{
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center; 
  position: relative;
}

.wheel-button{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.wheel-button::before{
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute;
}

.inner-button1{
  position: absolute;
  top: -3.11%;
  background: #93BF2C;
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-button2{
  position: absolute;
  transform: rotate(90deg);
  left: 65.51%;
  background: #93BF2C;
  width: 200px;
  height: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-button3{
  position: absolute;
  /* left: 25.51%;
  right: 60.09%; */
  top: 77.51%;
  background: #93BF2C;
  width: 200px;
  height: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-button4{
  position: absolute;
  right: 65.09%;
  background: #93BF2C;
  width: 200px;
  height: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(270deg);
}

.inner-button1::before{
  left: 45%;
  bottom: 15px;
  z-index:5000;
  border-top: 10px solid #93BF2C;
}
.inner-button2::before{
  left: 45%;
  bottom: -23px;
  border-top: 10px solid #93BF2C;;
}
.inner-button3::before{
  left: 45%;
  bottom: 103px;
  border-top: 10px solid #93BF2C;
  transform: rotate(180deg);
}
.inner-button4::before{
  left: 45%;
  bottom: -23px;
  border-top: 10px solid #93BF2C;;
}

.woliba-icon-wrapper{
  z-index: 2000;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(215 236 221 / 63%);
  .woliba-icon-inner-wrapper{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: pink;
    background: rgba(255, 255, 255, 1);    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .woliba-icon{
    width: 114px;
    height: 84px;
  }
}

.wrap {
  height: 400px;
  width: 400px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  transform: rotate(45deg);
  @media (max-width: 1200px){
    width: 200px;
    height: 200px;
  }
}
.quart {
  position: absolute;
  height: 50%;
  width: 50%;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.quart >span{
  transform: rotate(-45deg);
}
.quart::before{
  /* content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute; */
}

.quart1::before{
  /* left: 45%;
  bottom: 15px;
  z-index:5000;
  border-top: 10px solid #93BF2C; */
}

.quart1 >img{
  transform: rotate(-45deg);
  @media(max-width: 1200px){
    width: 85px;
    height: 85px;
    padding-top: 10px;
  }
}

.quart2 >img{
  transform: rotate(-47deg);
  padding-left: 14px;
  @media(max-width: 1200px){
    width: 85px;
    height: 85px;
    padding-top: 5px;
  }
}
.quart3 >img{
  transform: rotate(-45deg);
  padding-right: 14px;
  @media(max-width: 1200px){
    width: 85px;
    height: 85px;
    padding-top: 10px;
  }
}
.quart4 >img{
  transform: rotate(-42deg);
  padding-right: 14px;
  padding-top:10px;
  @media(max-width: 1200px){
    width: 105px;
    height: 85px;
    padding-top: 10px;
    padding-left: 10px;
  }
}

.quart:first-child {
  top: 0;
  left: 0;
  background: ${(props) => props.wheelBtnBg == 'engagement' || props.wheelBtnBg == 'enhance employee culture and company experience' ? '#9D87A9': 'rgb(157 135 169 / 50%)'};
  margin-left: -7px;
  margin-top: -7px;
}
.quart:nth-child(2) {
  top: 0;
  left: 50%;
  background: ${(props) => props.wheelBtnBg == 'rewards' || props.wheelBtnBg == 'enhance employee culture and company experience' ? '#FCC960': 'rgb(252 201 96 / 50%)'};
  margin-top: -7px;
}
.quart:nth-child(3) {
  top: 50%;
  left: 0;
  background: ${(props) => props.wheelBtnBg == 'coaching' || props.wheelBtnBg == 'enhance employee culture and company experience' ? '#69C2FF':'rgb(105 194 255 / 50%)'};
  margin-left: -7px;
}
.quart:nth-child(4) {
  top: 50%;
  left: 50%;
  background: ${(props) => props.wheelBtnBg == 'recognition' || props.wheelBtnBg == 'enhance employee culture and company experience' ?'#F6797D':'rgb(246 121 125 / 50%)'};
}
.center {
  height: 60%;
  width: 60%;
  position: absolute;
  top: 21%;
  left: 21%;
  background: lightgray;
  border-radius: 50%;
  text-align: center;
  line-height: 160px;
  transform: rotate(315deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.27);
}

.quart-icon-wrapper{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
    background: white;
    border-radius: 50%;
}

.woliba-icon{
  @media (max-width: 1200px){
    width: 60px;
  }
}

.content-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 50%;
  padding-left: 100px;
  @media (max-width: 1200px){
    width: 100%;
    padding-left: 0px;
    align-items: center;
    text-align: center;
  }
}
.wheel-content-header{
  font-family: 'Rubik-Medium';
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  color: #24395A;
  @media(max-width: 1200px){
    font-size: 18px;
    line-height: 24px;
  }
}

.wheel-content-text{
  font-family: 'Montserrat-Regular';
  font-size: 18px;
  line-height: 24px;
  color: #24395A;
  margin: 25px 0px 50px 0px;
  @media(max-width: 1200px){
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 30px;
    // padding-left: 25px;
    // padding-right: 25px;
  }
}
.learnMore-btn{
  background: #69C2FF;
  box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  padding: 18px 53px;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  @media (max-width: 1200px){
    width: 319px;
    height: 50px;
    // margin-top: 20px;
    margin-bottom: 30px;
    padding: 13px 53px 13px 53px; 
  }
}

`;

const WellnessWrapper = styled.div`  
  width:100%;
  display:flex;
  // height: 1020px;
  flex-direction:column;
  .wellness-uppersection{
    width:100%;
    text-align: center;
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1200px){
      padding-top: 50px;
    }
    .wellness-heading{
      font-family: 'Montserrat-Medium';
      font-style: normal;
      font-size: 24px;
      line-height: 30px;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      text-transform: capitalize; 
      color: #24395A; 
      @media (max-width: 1200px){
        display: none;
      }
    }
    .middle-text{
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      text-transform: capitalize; 
      color: #24395A;
      margin-top: 15px;
      @media (max-width: 1200px){
        font-size: 30px;
        line-height: 40px;
        margin-top: 0px;
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    .last-text{
      font-family: 'Montserrat-Regular';
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #24395A;
      margin-top: 15px;
      @media (max-width: 1200px){
        font-size: 16px;
        line-height: 24px;
        margin-top: 6px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    .explore-well{
      width: 250px;
      height: 60px;
      font-size: 18px;
      font-family: ${BoldFont};
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      // margin-left: 20px;
      background: #69C2FF;
      color: #fff;
      border: none;
      box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
      border-radius: 3px;
      // padding: 18px 35px;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      cursor: pointer;
      align-items: center;
      text-transform: uppercase;
      @media (max-width: 1200px){
        width: 319px;
        height: 50px;
        margin-top: 20px;
      }
    }
    .wellness-things{
      margin-top: 50px;
      margin-bottom: -6px;
      width: 1010px;
      @media(max-width: 1200px){
        display: none;
      }
    }
    .wellness-things-mobile{
      margin-top: 103px;
      margin-bottom: -6px;
      min-height: 250px;
      position: relative;
      margin: 0px 0px 70px;
      height: 200px;
      // left: -20px;
      top: 70px;
      @media(min-width: 1200px){
        display: none;
      }
    }
  }
  .continuous-wellbeing{
    // height: 401px;
    background: linear-gradient(180deg, #102B52 9.56%, #006FBC 114.61%);
    // display: -webkit-flex;
    // justify-content: center;
    // flex-direction: column;
    .wellbeing-topline{
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      text-transform: capitalize;
      color: #FFFFFF;
      margin-top: 75px;
      display: flex;
      justify-content: center;  
      @media(max-width: 1200px){
        font-size: 30px;
        line-height: 40px;
        margin-top: 50px;
        text-align: center;
        padding-left: 30px;
        padding-right: 25px;
      }
    }
    .middle-para{
      font-family: 'Montserrat-Medium';
      font-size: 20px;
      line-height: 24px;
      // display: flex;
      // align-items: center;
      // text-align: center;
      color: #FFFFFF;
      display: flex;
      text-align: center;
      justify-content: center;
      @media(max-width: 1200px){
        font-size: 19px;
        line-height: 24px;
        text-align: center;
        padding-left: 22px;
        padding-right: 22px;
        text-align: justify;
      }
      @media(max-width: 375px){
        font-size: 18px;
      }
      @media(max-width: 364px){
        font-size: 17px;
      }
    }
    .button-wrapper{
      display: flex;
      justify-content: center;
      @media(max-width: 1200px){
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .get-start{
        width: 250px;
        height: 60px;
        font-size: 18px;
        font-family: ${BoldFont};
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        // margin-left: 20px;
        background: #F6797D;
        color: #fff;
        border: none;
        box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
        border-radius: 3px;
        // padding: 18px 35px;
        display: flex;
        justify-content: center;
        margin-top: 40px;
        cursor: pointer;
        margin-right: 2%;
        margin-bottom: 75px;
        align-items: center;
        text-transform: uppercase;
        @media(max-width: 1200px){
          width: 319px;
          height: 50px;
          margin-bottom: 15px;
          margin-top: 20px;
          margin-right: 0px;
        }
      }
      .request-demo{
        width: 250px;
        height: 60px;
        font-size: 18px;
        font-family: ${BoldFont};
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        // margin-left: 20px;
        background: #69C2FF;
        color: #fff;
        border: none;
        box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
        border-radius: 3px;
        // padding: 18px 35px;
        display: flex;
        justify-content: center;
        margin-top: 40px;
        cursor: pointer;
        align-items: center;
        text-transform: uppercase;
        @media(max-width: 1200px){
          width: 319px;
          height: 50px;
          margin-top: 0px;
          margin-bottom: 50px;
        }
      }
    }
  }
`;

const EngagementWrapper = styled.div` 
  margin: 0 auto;
  height: 650px;
  width: 100%;
  max-width: 1200px;
  display: block;
  // font-family: ${RegularFont};
  // display: ${({intelligenceSection}) => intelligenceSection ? 'block' : 'flex'};
  align-items: flex-start;
  @media (max-width: 1200px){
    display: flex;
    flex-direction: column;
  }

`;

const EngagementTextContainer = styled.div` 
  display: inline-block;
  float: left;
  width: 50%;
  letter-spacing: 1.5px;
  @media (max-width: 1200px){
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .engagement-heading{
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    // align-items: center;
    text-align: left;
    text-decoration-line: underline;
    text-transform: capitalize; 
    color: #24395A; 
    margin-top: 122px;
    @media (max-width: 1200px){
      text-align: center;
      margin-top: 50px;
      font-size: 20px;
    }
  }
  .middle-engagement-text{
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-transform: capitalize;
    color: #24395A; 
    @media (max-width: 1200px){
      text-align: center;
      margin-top: 16px;
      font-size: 30px;
      line-height: 40px;
    }
  }
  .last-engagement-text{
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    // display: flex;
    // align-items: center;
    text-transform: capitalize;
    color: #24395A;
    letter-spacing: 0px;
    @media (max-width: 1200px){
      text-align: center;
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .explore-btn{
    width: 250px;
      height: 60px;
      font-size: 18px;
      font-family: ${BoldFont};
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      // margin-left: 20px;
      background: #69C2FF;
      color: #fff;
      border: none;
      box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
      border-radius: 3px;
      // padding: 18px 35px;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      cursor: pointer;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: normal;
      @media (max-width: 1200px){
        text-align: center;
        margin-top: 20px;
        width: 319px;
        // margin-left: 8%;
      }
  }

`;

const EngagementImageContainer = styled.div` 
  display: inline-block;
  float: right;
  width: 50%;
  margin-top: 75px;
  display: flex;
  justify-content: right;
  @media (max-width: 1200px){
    width: 100%;
    text-align: center;
    margin-top: 0px;
    display: flex;
    justify-content: flex-end;
  }
  .engagement-img{
    height: 503px;
    width: 718px
    @media(max-width: 1200px){
      display: none;
    }
  }

  .engagement-mobile-img{
    min-height: 250px;
    position: relative;
    margin: 0px 0px 70px;
    height: 200px;
    // left: -20px;
    top: 32px;
    @media(min-width: 1200px){
      display: none;
    }
  }
`;

const RewardWrapper = styled.div` 
  margin: 0 auto;
  height: 650px;
  width: 100%;
  max-width: 1200px;
  display: block;
  // font-family: ${RegularFont};
  // display: ${({intelligenceSection}) => intelligenceSection ? 'block' : 'flex'};
  align-items: flex-end;
  @media (max-width: 1200px){
    display: flex;
    flex-flow: column-reverse;
    margin-top: 100px;
    align-items: center;
  }

`;

const RewardImageContainer = styled.div` 
  display: inline-block;
  float: left;
  width: 50%;
  margin-top: 75px;
  display: flex;
  justify-content: left;
  @media (max-width: 1200px){
    width: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: flex-start;
  }
  .reward-img{
    @media(max-width: 1200px){
      display: none;
    }
  }

  .reward-mobile-img{
    min-height: 250px;
    position: relative;
    margin: 0px 0px 70px;
    height: 200px;
    // left: -20px;
    top: 35px;
    @media(min-width: 1200px){
      display: none;
    }
  }
`;

const RewardTextContainer = styled.div` 
  display: inline-block;
  float: right;
  // width: 50%;
  letter-spacing: 1.5px;
  @media(max-width: 1200px){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reward-heading{
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    // align-items: center;
    text-align: left;
    text-decoration-line: underline;
    text-transform: capitalize; 
    color: #24395A; 
    margin-top: 120px;
    @media (max-width: 1200px){
      text-align: center;
      font-size: 20px;
      line-height: 30px;

    }
  }
  .middle-reward-text{
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-transform: capitalize;
    color: #24395A; 
    @media (max-width: 1200px){
      text-align: center;
      font-size: 30px;
      line-height: 40px;
    }
  }
  .last-reward-text{
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    // display: flex;
    // align-items: center;
    text-transform: capitalize;
    color: #24395A;
    letter-spacing: 0px;
    @media (max-width: 1200px){
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .explore-btn2{
    width: 250px;
      height: 60px;
      font-size: 18px;
      font-family: ${BoldFont};
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      // margin-left: 20px;
      background: #69C2FF;
      color: #fff;
      border: none;
      box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
      border-radius: 3px;
      // padding: 18px 35px;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      cursor: pointer;
      align-items: center;
      text-transform: uppercase;
      @media (max-width: 1200px){
        text-align: center;
        margin-top: 20px;
        width: 319px;
      }
  }
`;

const RecognitionWrapper = styled.div`  
  width:100%;
  display:flex;
  // height: 1020px;
  flex-direction:column;
  background-color: #F5FBFF;
  .recognition-uppersection{
    width:100%;
    text-align: center;
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1200px){
      padding-top: 50px;
    }
    .recognition-heading{
      font-family: 'Montserrat-Medium';
      font-style: normal;
      font-size: 24px;
      line-height: 30px;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      text-transform: capitalize; 
      color: #24395A; 
      @media (max-width: 1200px){
        font-size: 20px;
        line-height: 30px;
      }
    }
    .middle-recognition-text{
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      text-transform: capitalize; 
      color: #24395A;
      margin-top: 15px;
      @media (max-width: 1200px){
        font-size: 30px;
        line-height: 40px;
        margin-top: 6px;
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    .last-recognition-text{
      font-family: 'Montserrat-Regular';
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #24395A;
      margin-top: 15px;
      text-transform: capitalize;
      @media (max-width: 1200px){
        font-size: 16px;
        line-height: 24px;
        margin-top: 6px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    .explore-recognition{
      width: 250px;
      height: 60px;
      font-size: 18px;
      font-family: ${BoldFont};
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      // margin-left: 20px;
      background: #69C2FF;
      color: #fff;
      border: none;
      box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
      border-radius: 3px;
      // padding: 18px 35px;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      cursor: pointer;
      align-items: center;
      text-transform: uppercase;
      @media (max-width: 1200px){
        width: 319px;
        line-height: 24px;
        margin-top: 20px;
      }
    }
    .recognition-things{
      margin-top: 50px;
      margin-bottom: 78px;
      width: 1018px;
      height: 524px;
      @media (max-width: 1200px){
        display: none;
      }
    }
    .recognition-mobile-things{
      margin-top: 50px;
      margin-bottom: 78px;
      min-height: 600px;
      position: relative;
      margin: 0px 0px 70px;
      height: 200px;
      // left: -20px;
      top: 35px;
      @media (min-width: 1200px){
        display: none;
      }
    }
  }
  `;

const  TopCard = styled.div`
  max-width:100%;
  margin:auto;
.topcard-demo-wrapper{
  width:100%;
  display:flex;
  justify-content:center;
  @media (max-width: 1000px){
    padding: 16px;
  }
  .topcard-demo{
    width:100%;
    max-width: 1200px;
    // height: 200px;
    border-radius: 6px;
    display:flex;
    margin-top:20px;
    background: linear-gradient(90deg, #102B52 29.41%, #006FBC 100%);
    margin-bottom: 25px;
    @media(max-width: 1000px){
      display: flex;
      flex-wrap: wrap;
      height: 100%;
    }
  .topcard-pic{
    margin-top:15px;
    margin-bottom:15px;
    margin-left:15px;
    @media(max-width: 1000px){
      width: 100%;
      height: 100%;
      margin: 0px;
    }
    .enter-img{
      @media(max-width: 1000px){
      width: 90%;
      display: flex;
      margin: auto;
      height: 90%;
      margin-top: 15px;
      }
    }
  } 
  .topcard-content{
    @media (max-width: 1000px){
      width: 100%
    }
    .upper-content{
      color: #F6B479;
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      margin-top:38px;
      margin-left:25px; 
      @media (max-width: 1000px){
        margin: auto;
        justify-content: center;
        margin-top: 15px;
      }
    }
    .lower-content{
      font-family: 'Montserrat-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;     
      color: #FFFFFF;
      margin-top:25px;
      margin-bottom:37px;
      margin-left:25px;
      @media (max-width: 1200px){
        width: auto;
        display: block;
        text-align: center;
        margin: auto;
        margin-top: 15px;
        font-size: 16px;
        display: none;
      }
      @media (max-width: 400px){
        font-size: 16px;
        padding-left: 30px;
        padding-right: 30px;
      }
      @media (max-width: 360px){
        font-size: 16px;
      }
      >p{
        margin-bottom:2px;
        // @media (max-width: 500px){
        //   padding-left: 20px;
        //   padding-right: 20px;
        // }
      } 
    }
  }
  .lower-mob-content{
    width: auto;
    display: block;
    text-align: center;
    margin: auto;
    margin-top: 15px;
    font-family: 'Montserrat-Regular';
    font-size: 16px;
    color: #FFFFFF;
    text-transform: capitalize;
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: 1200px){
      display: none;
    }
  }
  .demo-btn{
    width: 250px;
    height: 60px;
    background: #F6B479;
    box-shadow: 9px 12px 13px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin: auto;
    margin-right: 32px;
    border: none;
    @media (max-width: 1000px){
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .watch-text{
      width: 196px;
      height: 24px;
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 24px;      
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;     
      color: #FFFFFF;  
      margin: 0px 28px 0px 24px;
      @media (max-width: 280px){
        width: auto;
      }
    }
  }
  }
}
`;

const DemoText = styled.div`
 font-family: Rubik;
 font-size: 18px;
 line-height: 20px;
 text-align:left;
 margin-left:75px;
 width:90%;
 color: white;
 margin-bottom:40px;
 @media(max-width:500px){
  width:100%
  text-align:center;
  margin-left:0px;
 }
 @media(max-width:700px){
  width:100%
  text-align:center;
  margin-left:0px;
 }
`;

export {
  Section,
  ImageContainer,
  Button,
  ButtonContainer,
  ImageWrapper,
  IntroTextContainer,
  VideoButton,
  PosterImage,
  PromotionalContainer,
  InformationContainer,
  PlatformInformation,
  PlatformItemContainer,
  PlatformItem,
  LogoContainer,
  HealthContainer,
  GrassRootItem,
  GetStartedContainer,
  ButtonBookDemo,
  ButtonLearnMoree,
  MainWrapper,
  GlobalMainWrapper,
  ShowLogos,
  CompanyMainWrapper,
  WellBeingMainWrapper,
  RatingsContainers,
  CustomListItemV2Main,
  MenuListV2Main,
  TeamWrapper,
  ButtonTeam,
  Wrapper,
  TestimonialWrapper,
  RatingWrapper,
  CustomList,
  CoreWrapper,
  TabsContainerV2,
  MenuListV2Main2,
  ProductWrapper,
  Divider,
  CurveWrapper,
  Button2,
  WrapperContainer,
  ButtonBookDemoo,
  MenuLinkss,
  CoreList,
  TabsContainerTestimonial,
  ResponsiveTestimonialTab,
  Banner,
  Support,
  SubSupport,
  AwardSection,
  WellBeing,
  ButtonGetStarted,
  BannerCurve,
  ButtonBookDemoNew,
  AboutWolibaWrapper,
  WheelWrapperNew,
  WellnessWrapper,
  EngagementWrapper,
  EngagementTextContainer,
  EngagementImageContainer,
  RewardWrapper,
  RewardImageContainer,
  RewardTextContainer,
  RecognitionWrapper,
  TopCard,
  DemoText
};
